<template>
  <div>
    <div>
      <h1
        class="header-text pb-3"
        ref="headerEditProfile"
        aria-labelledby="header for Interest Areas / Pairing Factors page"
        data-cy="header-admin-reports-interest-pairing"
        autofocus
      >
        Interest Areas / Pairing Factors
      </h1>
      <div class="flex">
        <div class="flex-auto">
          <div class="mb-2">Term</div>
          <select
            class="mb-2 w-1/2 rounded border-dark-gray py-1"
            v-model="term"
          >
            <option
              class="h-8"
              v-for="(term, key) in terms"
              :key="key"
              :value="term"
            >
              {{ term.name }}
            </option>
          </select>
        </div>
        <div class="text-right flex-auto py-6">
          <Button
            class=""
            text="Download Data"
            @click="downloadData('interest_pairing.csv', records)"
          />
        </div>
      </div>
    </div>
    <div class="box-style mb-6">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.records"
          :fields="tableConfig"
          no-data-msg=""
          striped
          hover
        >
          <template #cell(interest_area_1_updated)="{ item }">
            {{ this.FormatDate(item.interest_area_1_updated) }}
          </template>
          <template #cell(interest_area_2_updated)="{ item }">
            {{ this.FormatDate(item.interest_area_2_updated) }}
          </template>
          <template #cell(interest_area_3_updated)="{ item }">
            {{ this.FormatDate(item.interest_area_3_updated) }}
          </template>
          <template #cell(interest_area_4_updated)="{ item }">
            {{ this.FormatDate(item.interest_area_4_updated) }}
          </template>
          <template #cell(interest_area_5_updated)="{ item }">
            {{ this.FormatDate(item.interest_area_5_updated) }}
          </template>
          <template #cell(pairing_factor_1_updated)="{ item }">
            {{ this.FormatDate(item.pairing_factor_1_updated) }}
          </template>
          <template #cell(pairing_factor_2_updated)="{ item }">
            {{ this.FormatDate(item.pairing_factor_2_updated) }}
          </template>
          <template #cell(pairing_factor_3_updated)="{ item }">
            {{ this.FormatDate(item.pairing_factor_3_updated) }}
          </template>
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get } from "@/composables/httpUtil";
import { downloadData } from "@/composables/downloadUtils";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";
import {FormatDate} from "@/composables/date_utils";

export default {
  name: "Pairings",
  components: { LoadingSpinner, DynamicTable, Button },
  data() {
    return {
      isLoading: true,
      records: [],
      term: null,
      terms: [],
      tableConfig: [
        {
          display: "Header Name",
          name: "first_name",
          sortable: false,
          class: "",
        },
        {
          display: "Header Name",
          name: "last_name",
          sortable: false,
          class: "",
        },
        {
          display: "Header Name",
          name: "level",
          sortable: false,
          class: "",
        },
        {
          display: "Header Name",
          name: "email_address",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 1",
          name: "interest_area_1",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 1 Updated",
          name: "interest_area_1_updated",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 2",
          name: "interest_area_2",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 2 Updated",
          name: "interest_area_2_updated",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 3",
          name: "interest_area_3",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 3 Updated",
          name: "interest_area_3_updated",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 4",
          name: "interest_area_4",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 4 Updated",
          name: "interest_area_4_updated",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 5",
          name: "interest_area_5",
          sortable: false,
          class: "",
        },
        {
          display: "Interest Area 5 Updated",
          name: "interest_area_5_updated",
          sortable: false,
          class: "",
        },
        {
          display: "Pairing Factor 1",
          name: "pairing_factor_1",
          sortable: false,
          class: "",
        },
        {
          display: "Pairing Factor 1 Updated",
          name: "pairing_factor_1_updated",
          sortable: false,
          class: "",
        },
        {
          display: "Pairing Factor 2",
          name: "pairing_factor_2",
          sortable: false,
          class: "",
        },
        {
          display: "Pairing Factor 2 Updated",
          name: "pairing_factor_2_updated",
          sortable: false,
          class: "",
        },
        {
          display: "Pairing Factor 3",
          name: "pairing_factor_3",
          sortable: false,
          class: "",
        },
        {
          display: "Pairing Factor 3 Updated",
          name: "pairing_factor_3_updated",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.fetchLookupResource().then(
      get("admin/terms")
        .then((terms) => {
          this.terms = terms.data;
          this.term = this.lookup_current_term;
        })
        .finally(() => {
          this.$watch(
            (vm) => vm.term,
            () => {
              this.isLoading = true;
              this.getData();
            },
            { immediate: true }
          );
          this.isLoading = false;
        })
    );
  },
  methods: {
    downloadData,
    FormatDate,
    getData() {
      let url = `admin/reports/interest_pairing_factors?term=${this.term.term_id}`;
      get(url)
        .then((results) => {
          this.records = results;
        })
        .catch((error) => {
          console.log("error in catch statement", error);
          this.records = [];
        })
        .finally(() => (this.isLoading = false));
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
  },
};
</script>

<style scoped></style>
